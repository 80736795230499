import { useMemo } from "react";
import Loader from "./Loader";

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src?: string;
  className?: string;
  alt?: string;
  url?: boolean;
  // props?: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
}

const Image = ({ src, alt, className, url, ...props }: ImageProps) => {
  const apiOrgin = useMemo(
    () =>
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_PROD_API_URL
        : process.env.REACT_APP_DEV_API_URL,
    []
  ) as string;

  const image = url ? apiOrgin + src : src;

  if (url && src === undefined) return <Loader />;

  return (
    <img
      src={image}
      className={"object-cover " + className}
      alt={alt}
      {...props}
    />
  );
};

export default Image;
