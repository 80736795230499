import React from "react";
import { RiArrowDropLeftLine, RiArrowDropRightLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { Post } from "../api/post";
import Button from "./design-system/Button";
import ProtectedImage from "./design-system/ProtectedImage";

interface Props {
  post: Post;
  posts?: Post[];
  setPost: React.Dispatch<React.SetStateAction<Post | null>>;
  index?: number;
  setIndex?: React.Dispatch<React.SetStateAction<number>>;
  lock?: boolean;
}

const ViewerModal = ({
  post,
  setPost,
  posts = [post],
  index = 0,
  setIndex,
  lock = false,
}: Props) => {
  const navigate = useNavigate();

  const onClickPrevious = () => {
    if (setIndex && index > 0) {
      setPost(posts[index - 1]);
      setIndex(index - 1);
    }
  };

  const onClickNext = () => {
    if (setIndex && index < posts.length - 1) {
      setPost(posts[index + 1]);
      setIndex(index + 1);
    }
  };

  return (
    <div className="flex justify-center items-center fixed inset-0 z-50 outline-none focus:outline-none  w-full h-full">
      <div
        className="relative justify-center items-center flex overflow-x-hidden overflow-y-auto  outline-none focus:outline-none h-full w-full bg-black opacity-70"
        onClick={() => setPost(null)}
      />
      <div className="absolute flex justify-center items-center outline-none focus:outline-none w-11/12 h-5/6">
        <div className="md:flex md:justify-center w-full h-full">
          <div className="relative flex justify-center w-full md:w-9/12 bg-dark-blue">
            <ProtectedImage
              lock={lock}
              src={post.file}
              className=" object-cover"
            />
            <div className="absolute h-full w-full flex justify-between items-center pl-4 pr-4">
              {index > 0 && (
                <button
                  className="w-8 h-8 bg-slate-500 rounded-full cursor-pointer hover:bg-slate-400 font-extrabold text-white flex justify-center items-center"
                  onClick={() => onClickPrevious()}
                >
                  <RiArrowDropLeftLine className="w-6 h-6" />
                </button>
              )}
              {posts.length > 1 && index < posts.length - 1 && (
                <button
                  className="w-8 h-8 bg-slate-500 rounded-full cursor-pointer hover:bg-slate-400 font-extrabold text-white flex justify-center items-center"
                  onClick={() => onClickNext()}
                >
                  <RiArrowDropRightLine className="w-6 h-6" />
                </button>
              )}
            </div>
          </div>

          <div className="md:h-full w-full mb:w-3/12 md:ml-4 mt-2 md:mt-0 bg-white rounded-xl p-4">
            <div className="flex">
              <ProtectedImage
                src={post.profile_pic}
                className="h-16 w-16 rounded-full border border-black shrink-0"
              />
              <button
                className="text-dark-blue font-bold algin-center text-xl pl-4 hover:text-light-blue truncate"
                onClick={() => navigate("/" + post.username)}
              >
                {post.username}
              </button>
            </div>
            <div className="mt-4 text-dark-blue">{post.description}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewerModal;
