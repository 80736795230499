import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useNavigation } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateToDiscover = useCallback(
    () => navigate("/discover"),
    [navigate]
  );
  const navigateToPrivacyPolicy = useCallback(
    () => navigate("/privacy-policy"),
    [navigate]
  );
  const navigateToTermsOfService = useCallback(
    () => navigate("/terms-of-service"),
    [navigate]
  );
  const navigateTContactUs = useCallback(
    () => navigate("/contact-us"),
    [navigate]
  );
  return (
    <footer className="py-8 px-8 md:px-16 bg-gray-900 text-center text-gray-500">
      <div className="space-x-4">
        <a
          onClick={navigateToDiscover}
          href="#discover"
          className="hover:underline"
        >
          {t("footer.about")}
        </a>
        <a
          onClick={navigateToPrivacyPolicy}
          href="#privacy-policy"
          className="hover:underline"
        >
          {t("footer.privacyPolicy")}
        </a>
        <a
          onClick={navigateToTermsOfService}
          href="#terms-of-service"
          className="hover:underline"
        >
          {t("footer.termsOfService")}
        </a>
        <a
          onClick={navigateTContactUs}
          href="#contact-us"
          className="hover:underline"
        >
          {t("footer.contactUs")}
        </a>
      </div>
      <p className="mt-4">&copy; {t("footer.allRightsReserved")}</p>
    </footer>
  );
};

export default Footer;
