import './Dropdown.css'

interface DropdownProps {
  options?: JSX.Element
  children: JSX.Element
  className?: string
}

const Dropdown = ({options, children, className}: DropdownProps) => {

  function toggleDropdown() {
    const dropdown = document.getElementById('dropdown');
    dropdown?.classList.toggle('hidden');
  }
  
  return (
//  <>  
// <button onClick={() => toggleDropdown()} id="dropdownDefaultButton" data-dropdown-toggle="dropdown" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">Dropdown button </button>

// <div id="dropdown" className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
//     <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
//       <li>
//         <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dashboard</a>
//       </li>
//       <li>
//         <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Settings</a>
//       </li>
//       <li>
//         <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Earnings</a>
//       </li>
//       <li>
//         <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Sign out</a>
//       </li>
//     </ul>
// </div>
// </>
    
    
     <div className={'dropdown ' + className} >
      {children}
      <div className="dropdown-content rounded-lg text-dark-blue ">
        {options}
      </div>
    </div>
  )
}

export default Dropdown