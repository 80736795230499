import { useState } from "react";
import { useRegisterToNewsletter } from "../api/newsletter";
import Button from "../components/design-system/Button";
import Input from "../components/design-system/Input";
import Loader from "../components/design-system/Loader";
import { useTranslation } from "react-i18next";

const RegisterToNewsletterForm = () => {
  const [email, setEmail] = useState("");
  const { mutate: registerToNewsletter, isLoading } = useRegisterToNewsletter();
  const { t } = useTranslation();

  const handleRegistration = () => {
    if (email) registerToNewsletter(email);
  };

  return (
    <>
      <div className="space-y-4 text-white py-16 text-lg">
        <p>{t("landing.registerToNewsletter.interested")}</p>
        <p>{t("landing.registerToNewsletter.first_user")}</p>
        <p>{t("landing.registerToNewsletter.support")}</p>
      </div>

      <p className="text-lg">{t("landing.registerToNewsletter.register")}</p>
      <Input
        placeholder="Enter your email"
        onChange={(e) => setEmail(e.currentTarget.value)}
        value={email}
      />
      <Button disabled={!email} onClick={() => handleRegistration()}>
        {isLoading ? <Loader /> : t("common.actions.subscribe")}
      </Button>
    </>
  );
};

export default RegisterToNewsletterForm;
