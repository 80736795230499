import { useTranslation } from "react-i18next";
import PageWrapper from "../components/PageWrapper";

const TermsOfService = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <div className="container mx-auto px-4 py-16 text-slate-300">
        <h1 className="text-4xl font-bold mb-8 text-white">
          {t("termsOfService.title")}
        </h1>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-white">
            {t("termsOfService.section1.title")}
          </h2>
          <p>{t("termsOfService.section1.content")}</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-white">
            {t("termsOfService.section2.title")}
          </h2>
          <p>{t("termsOfService.section2.content")}</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-white">
            {t("termsOfService.section3.title")}
          </h2>
          <p>{t("termsOfService.section3.content")}</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-white">
            {t("termsOfService.section4.title")}
          </h2>
          <p>{t("termsOfService.section4.content")}</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-white">
            {t("termsOfService.section5.title")}
          </h2>
          <p>{t("termsOfService.section5.content")}</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-white">
            {t("termsOfService.section6.title")}
          </h2>
          <p>{t("termsOfService.section6.content")}</p>
        </section>

        <p>{t("termsOfService.lastUpdated", { date: "[Date]" })}</p>
      </div>
    </PageWrapper>
  );
};

export default TermsOfService;
