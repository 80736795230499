import { RiFlag2Line } from 'react-icons/ri'
import { BsThreeDots } from 'react-icons/bs'
import Dropdown from './design-system/Dropdown'
import { useState } from 'react'
import Modal from './design-system/Modal'
import ReportAbuseModal from './ReportAbuseModal'
interface SignalButtonProps {
  postId: string
}

const SignalButton = ({postId}: SignalButtonProps) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <button 
        className='flex items-center hover:text-light-blue' 
        type="button"
        // disabled={}
        onClick={() => setShowModal(true) }
      >
        <RiFlag2Line className="h-6 w-6 mr-2"/>
        Report an abuse
      </button>
      <ReportAbuseModal showModal={showModal} setShowModal={setShowModal} postId={postId}/>
    </>
  ) 
}

export default SignalButton






interface MoreButtonProps {
  postId: string
}

export const MoreButton = ({postId}: MoreButtonProps) => {

  const options = <>
    <SignalButton postId={postId}/>
  </>

  return (
    <Dropdown 
      className="flex items-center px-2 text-dark-blue hover:text-light-blue"
      options={options}
    >
      <BsThreeDots className='h-6 w-6'/>
    </Dropdown>
  )
}