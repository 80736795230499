interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
  required?: boolean;
  onChange?: (value: React.FormEvent<HTMLInputElement>) => void;
  name?: string;
  className?: string;
  labelClassName?: string;
}

const Input = ({
  label,
  placeholder,
  type = "text",
  disabled = false,
  required = false,
  className = "",
  labelClassName = "",
  onChange,
  ...props
}: IProps) => {
  return (
    <label className={"block my-5 text-slate-700" + className}>
      {label && (
        <span
          className={
            "block text-sm font-medium text-slate-700 " + labelClassName
          }
        >
          {required && <b className="text-red-400">* </b>}
          {label}
        </span>
      )}
      <input
        type={type}
        onChange={onChange}
        // value="tbone"
        placeholder={placeholder}
        disabled={disabled}
        className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
            focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
            disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
            invalid:border-pink-500 invalid:text-pink-600
            focus:invalid:border-pink-500 focus:invalid:ring-pink-500
        "
        {...props}
      />
    </label>
  );
};

export default Input;
