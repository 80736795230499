import { useTranslation } from "react-i18next";
import FR from "../assets/france.png";
import EN from "../assets/united-kingdom.png";
import Image from "./design-system/Image";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useCallback, useState } from "react";

type AllowedLanguage = "en" | "fr";

const LANGUAGES: { value: AllowedLanguage; image: string }[] = [
  {
    value: "en",
    image: EN,
  },
  {
    value: "fr",
    image: FR,
  },
];

const LANGUAGE_MAPPING: Record<AllowedLanguage, string> = {
  en: EN,
  fr: FR,
};

const LanguagePicker = () => {
  const { i18n } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const closePicker = useCallback(() => setIsOpen(false), []);
  const openPicker = useCallback(() => setIsOpen(true), []);

  const togglePicker = useCallback(
    () => setIsOpen((currentValue) => !currentValue),
    []
  );

  const changeLanguage = useCallback(
    (language: AllowedLanguage) => {
      i18n.changeLanguage(language);
      closePicker();
    },
    [closePicker, i18n]
  );

  return (
    <div onMouseLeave={closePicker} onMouseEnter={openPicker}>
      <div className="flex" onClick={togglePicker}>
        <Image
          src={LANGUAGE_MAPPING[i18n.language as AllowedLanguage]}
          className="w-6 h-6"
        />
        <MdKeyboardArrowDown />
      </div>
      {isOpen && (
        <div className="absolute -ml-4 z-10 bg-dark-blue p-4 rounded">
          {LANGUAGES.map((language) => (
            <Image
              key={language.value}
              src={language.image}
              className="w-6 h-6 hover:opacity-70"
              onClick={() => changeLanguage(language.value)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguagePicker;
