
import { User } from '../../api/user'
import { RiCloseFill, RiMenuFill } from 'react-icons/ri';

interface Props {
  isOpen: boolean
  children?: JSX.Element
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Drawer = ({isOpen, setIsOpen, children }: Props) => {


  return (
    <>
      {isOpen ? (
        <button
          className="flex text-4xl text-white items-center cursor-pointer fixed right-10 top-6 z-50 hover:text-light-blue"
          onClick={() => setIsOpen(false)}
        >
          <RiCloseFill  className='h-10 w-10'/>
        </button>
      ) : (

        <RiMenuFill 
          className='h-12 w-12 hover:text-light-blue cursor-pointer'
          onClick={() => setIsOpen(!isOpen)} />
      )}

      <div
        className={`top-20 right-0 w-80 bg-dark-blue pl-10 text-white fixed h-full z-40  ease-in-out duration-300 ${
          isOpen ? 'translate-x-0 ' : 'translate-x-full'
        }`}
      >
        {children}
      </div>
    </>
  );

}

export default Drawer






// <svg
//   onClick={() => setShowSidebar(!showSidebar)}
//   className=" z-30 flex items-center cursor-pointer right-10 top-6 fill-white hover:fill-light-blue"
//   fill="#35A7E7"
//   viewBox="0 0 100 80"
//   width="40"
//   height="40"

// >
//   <rect width="100" height="10" ></rect>
//   <rect y="30" width="100" height="10" ></rect>
//   <rect y="60" width="100" height="10"></rect>
// </svg>