import { useNavigate } from 'react-router-dom'
import { User } from '../api/user'
import FORYOU_LOGO from '../assets/4you-logo.png'
import Image from './design-system/Image'
import SlidingPanel from './SlidingPanel'

interface Props {
  user: User
}

const Header = ({user}: Props) => {
  const navigate = useNavigate()

  return (

    <div className="fixed  bg-dark-blue text-white h-20 w-full z-10">
    <div className="flex justify-between">
      <Image 
        src={FORYOU_LOGO} alt="4 YOU logo" 
        onClick={() => navigate('/')} className='cursor-pointer'/>

      <div className="p-2 pr-8 flex space-x-2 items-center">
        <SlidingPanel user={user}/>
      </div>
    </div>
  </div>

  )
}

export default Header
