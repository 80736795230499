import { useTranslation } from "react-i18next";
import PageWrapper from "../components/PageWrapper";

function PrivacyPolicy() {
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <div className="container mx-auto px-4 py-16 text-slate-300">
        <h1 className="text-4xl font-bold mb-8">{t("privacyPolicy.title")}</h1>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-white">
            {t("privacyPolicy.section1.title")}
          </h2>
          <p>{t("privacyPolicy.section1.content")}</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-white">
            {t("privacyPolicy.section2.title")}
          </h2>
          <p>{t("privacyPolicy.section2.content")}</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-white">
            {t("privacyPolicy.section3.title")}
          </h2>
          <p>{t("privacyPolicy.section3.content")}</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-white">
            {t("privacyPolicy.section4.title")}
          </h2>
          <p>{t("privacyPolicy.section4.content")}</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-white">
            {t("privacyPolicy.section5.title")}
          </h2>
          <p>{t("privacyPolicy.section5.content")}</p>
        </section>

        <p>{t("privacyPolicy.lastUpdated", { date: "[Date]" })}</p>
      </div>
    </PageWrapper>
  );
}

export default PrivacyPolicy;
