/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { RiLock2Fill } from "react-icons/ri";
import Loader from "./Loader";

interface Props {
  src: string;
  className?: string;
  alt?: string;
  lock?: boolean;
}

const ProtectedImage = ({ src, alt, className, lock = false }: Props) => {
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const getImage = useCallback(async () => {
    setLoading(true);
    const response = await axios.get(src, { responseType: "blob" });
    if (response.status === 200) {
      const reader = new FileReader();
      reader.onload = () => {
        const dataUri = reader.result;
        setImageUrl(dataUri as string);
        setLoading(false);
      };
      reader.readAsDataURL(response.data);
    } else {
      setLoading(false);
      setImageUrl("");
    }
  }, [src]);

  useEffect(() => {
    if (!lock && src) getImage();
  }, [lock, src, getImage]);

  if (loading) return <Loader />;

  if (lock || !imageUrl)
    return (
      <div
        className={
          "bg-slate-400 h-full w-full px-4 py-8 flex items-center justify-center aspect-square" +
          className
        }
      >
        <div>
          <div className="flex justify-center">
            <RiLock2Fill className="h-2/5 w-2/5 text-dark-blue" />
          </div>
          <p>You need to subscribe to see this content.</p>
        </div>
      </div>
    );

  return (
    <img src={imageUrl} className={"object-cover " + className} alt={alt} />
  );
};

export default ProtectedImage;
