import React, { useCallback } from "react";
import { PostItem } from "../components/PostsList";
import Image from "../components/design-system/Image";
import { useLanding } from "../api/landing";
import RegisterToNewsletterForm from "./RegisterToNewsletterForm";
import Loader from "../components/design-system/Loader";
import { useTranslation } from "react-i18next";
import { FaUserPlus } from "react-icons/fa";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { FaUserLock } from "react-icons/fa";
import { FaExchangeAlt } from "react-icons/fa";
import { FaFileImage } from "react-icons/fa";
import { FaMedal } from "react-icons/fa";
import IMAGE1 from "../assets/1millidollars-IIAh6Gl0oEQ-unsplash.jpg";
import IMAGE2 from "../assets/engineer-style-mF3ouZe8aZk-unsplash.jpg";
import IMAGE3 from "../assets/garin-chadwick-0qodft4ZK7Q-unsplash.jpg";
import IMAGE4 from "../assets/jc-gellidon-Ikaw1-lupmI-unsplash.jpg";
import IMAGE5 from "../assets/josh-duke-No1TxIvS5Lw-unsplash.jpg";
import IMAGE6 from "../assets/nahima-aparicio-Q6PrbE7ZbSg-unsplash.jpg";
import IMAGE7 from "../assets/norbert-buduczki-QCGSYQr8I_M-unsplash.jpg";
import IMAGE8 from "../assets/thiago-reboucas-Eu6Y58PAPRo-unsplash.jpg";
import IMAGE9 from "../assets/thiago-reboucas-IT7udPaoPxc-unsplash.jpg";
import SMOKE from "../assets/smoke.png";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import PublicHeader from "../components/PublicHeader";
import PageWrapper from "../components/PageWrapper";

const LIGHT_BLUE = "#35A7E7";

const Landing = () => {
  const { data: landing, isLoading } = useLanding();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToSignup = useCallback(() => navigate("/signup"), [navigate]);

  const isLoginEnabled = process.env.NODE_ENV === "development";

  if (isLoading) return <Loader />;

  return (
    <>
      <PageWrapper>
        {/* <!-- Hero Section --> */}
        <section
          className={`flex flex-col items-center justify-center h-screen bg-cover bg-center bg-dark-blue text-white`}
        >
          <div className="h-full w-full ">
            <div className="smoke-wrap">
              <img className="smoke h-32" src={SMOKE} alt="smoke" />
            </div>
            <div className="smoke-wrap">
              <img className="smoke2 h-32" src={SMOKE} alt="smoke" />
            </div>
            <div className="smoke-wrap">
              <img className="smoke3 h-32" src={SMOKE} alt="smoke" />
            </div>
          </div>
          <div className="absolute">
            <h1 className="text-5xl md:text-6xl font-bold mb-4 text-center">
              {t("landing.hero.title")}
            </h1>
            <p className="text-xl md:text-2xl mb-8 text-center">
              {t("landing.hero.subtitle")}
            </p>
            <div className="space-x-4 flex justify-center lg:justify-start">
              {isLoginEnabled && (
                <button
                  className="bg-light-blue hover:bg-blue-600 text-white font-semibold py-3 px-6 rounded-md"
                  onClick={navigateToSignup}
                >
                  {t("common.actions.signUp")}
                </button>
              )}
              {!isLoginEnabled && (
                <button
                  className="bg-light-blue hover:bg-blue-600 text-white font-semibold py-3 px-6 rounded-md"
                  onClick={() => {
                    window.location.href = "#subscribe";
                  }}
                >
                  {t("common.actions.subscribe")}
                </button>
              )}
              <button
                className="bg-transparent border-2 border-white hover:bg-white hover:text-gray-900 font-semibold py-3 px-6 rounded-md"
                onClick={() => {
                  window.location.href = "#more";
                }}
              >
                {t("common.actions.discover")}
              </button>
            </div>
          </div>
        </section>

        {/* <!-- Key Features Section --> */}
        <section
          className="py-16 px-8 md:px-16 bg-dark-blue text-center text-white"
          id="more"
        >
          <h2 className="text-4xl font-semibold mb-8">
            {t("landing.keyFeatures.title")}
          </h2>
          <div className="flex h-screen">
            <div className="flex justify-start items-center w-1/2">
              <Image
                src={IMAGE1}
                alt="exclusive-content"
                className="h-2/3 w-1/5 rounded-xl"
                loading="lazy"
              />
              <Image
                src={IMAGE2}
                alt="exclusive-content"
                className="mt-16 ml-8 md:ml-16 h-2/3 w-1/5 rounded-xl"
                loading="lazy"
              />
              <Image
                src={IMAGE3}
                alt="exclusive-content"
                className="mt-32 ml-8 md:ml-16 h-2/3 w-1/5 rounded-xl"
                loading="lazy"
              />
            </div>
            <div className="flex-1 flex flex-col justify-center items-center w-1/2 z-10">
              <FaMedal className="h-16 w-16 mb-6" />
              <h3 className="text-3xl md:text-4xl font-bold mb-2">
                {t("landing.keyFeatures.exclusiveContent.title")}
              </h3>
              <p className="text-lg">
                {t("landing.keyFeatures.exclusiveContent.description")}
              </p>
            </div>
          </div>

          <div className="flex h-screen">
            <div className="flex flex-col justify-center items-center w-1/2 z-10">
              <FaExchangeAlt className="h-16 w-16 mb-6" />
              <h3 className="text-3xl md:text-4xl font-bold mb-2">
                {t("landing.keyFeatures.flexibleMembership.title")}
              </h3>
              <p className="text-lg">
                {t("landing.keyFeatures.flexibleMembership.description")}
              </p>
            </div>
            <div className="flex flex-row-reverse justify-start items-center w-1/2 px-2">
              <Image
                src={IMAGE4}
                alt="exclusive-content"
                className="h-2/3 w-1/5 rounded-xl"
                loading="lazy"
              />
              <Image
                src={IMAGE5}
                alt="exclusive-content"
                className="mt-16 mr-8 md:mr-16 h-2/3 w-1/5 rounded-xl"
                loading="lazy"
              />
              <Image
                src={IMAGE6}
                alt="exclusive-content"
                className="mt-32 mr-8 md:mr-16 h-2/3 w-1/5 rounded-xl"
                loading="lazy"
              />
            </div>
          </div>

          <div className="flex h-screen">
            <div className="flex justify-center items-center w-1/2">
              <Image
                src={IMAGE7}
                alt="exclusive-content"
                className="h-2/3 w-1/5 rounded-xl"
                loading="lazy"
              />
              <Image
                src={IMAGE8}
                alt="exclusive-content"
                className="mt-16 ml-8 md:ml-16 h-2/3 w-1/5 rounded-xl"
                loading="lazy"
              />
              <Image
                src={IMAGE9}
                alt="exclusive-content"
                className="mt-32 ml-8 md:ml-16 h-2/3 w-1/5 rounded-xl"
                loading="lazy"
              />
            </div>
            <div className="flex flex-col justify-center items-center w-1/2 z-10">
              <FaUserLock className="h-16 w-16 mb-6" />
              <h3 className="text-3xl md:text-4xl font-bold mb-2">
                {t("landing.keyFeatures.privacySecurity.title")}
              </h3>
              <p className="text-lg">
                {t("landing.keyFeatures.privacySecurity.description")}
              </p>
            </div>
          </div>
        </section>

        {/* <!-- How It Works Section --> */}
        <section className="py-16 px-8 md:px-16 bg-dark-blue text-white text-center ">
          <h2 className="text-4xl font-semibold mb-16">
            {t("landing.howItWorks.title")}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="p-6 border border-light-blue rounded-lg flex flex-col items-center">
              <FaUserPlus className="h-16 w-16 mb-6" />
              <h3 className="text-xl font-bold mb-2">
                {t("landing.howItWorks.create_account.title")}
              </h3>
              <p>{t("landing.howItWorks.create_account.description")}</p>
            </div>
            <div className="p-6 border border-light-blue rounded-lg flex flex-col items-center">
              <FaMagnifyingGlass className="h-16 w-16 mb-6" />

              <h3 className="text-xl font-bold mb-2">
                {t("landing.howItWorks.browse_and_subscribe.title")}
              </h3>
              <p>{t("landing.howItWorks.browse_and_subscribe.description")}</p>
            </div>
            <div className="p-6 border border-light-blue rounded-lg flex flex-col items-center">
              <FaFileImage className="h-16 w-16 mb-6" />

              <h3 className="text-xl font-bold mb-2">
                {t("landing.howItWorks.exclusive_access.title")}
              </h3>
              <p>{t("landing.howItWorks.exclusive_access.description")}</p>
            </div>
          </div>
        </section>

        {/* <!-- Testimonials Section --> */}
        {/* <section className="py-16 px-8 md:px-16 bg-gray-800 text-center">
        <h2 className="text-3xl font-semibold mb-8">
          See What Fans & Creators Are Saying
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="p-6 bg-gray-700 rounded-lg">
            <p>
              "The best platform to access exclusive content from my favorite
              creators!"
            </p>
            <p className="mt-4 font-semibold">- Alex, Fan</p>
          </div>
          <div className="p-6 bg-gray-700 rounded-lg">
            <p>
              "4You Only makes it easy for me to connect with my fans and share
              exclusive content!"
            </p>
            <p className="mt-4 font-semibold">- Jamie, Creator</p>
          </div>
        </div>
      </section> */}

        {/* <!-- Creator Spotlight Section --> */}
        <section className="py-16 px-8 md:px-16 bg-dark-blue text-white text-center flex flex-col items-center">
          <h2 className="text-4xl font-semibold mb-16">
            {t("landing.featuredCreators.title")}
          </h2>

          <div className="md:w-2/3 lg:w-1/3">
            <PostItem
              demo
              post={{
                id: "1",
                profile_id: "1",
                description: landing?.first_description,
                file: landing?.first_post_file,
                profile_pic: landing?.first_profile_pic,
                username: landing?.first_username,
                liked: false,
                likes_count: 1643,
              }}
              textColor="white"
            />
            <div className="h-20" />
            <PostItem
              demo
              lock
              post={{
                id: "2",
                profile_id: "1",
                description: "Get ready for tonight live show!! 🤫",
                file: "",
                profile_pic: landing?.first_profile_pic,
                username: landing?.first_username,
                liked: false,
                likes_count: 566,
              }}
            />
            <div className="h-20" />
            <PostItem
              demo
              post={{
                id: "3",
                profile_id: "2",
                description: landing?.second_description,
                file: landing?.second_post_file,
                profile_pic: landing?.second_profile_pic,
                username: landing?.second_username,
                liked: false,
                likes_count: 377,
              }}
            />
          </div>
        </section>

        {/* <!-- Call to Action Section --> */}
        <section className="py-16 px-8 md:px-16 bg-light-blue text-center">
          <h2 className="text-3xl font-semibold mb-4 text-white">
            {t("landing.callToAction.title")}
          </h2>
          <button
            className="bg-white text-light-blue font-semibold py-3 px-8 rounded-md hover:bg-gray-100"
            onClick={() => {
              window.location.href = "#subscribe";
            }}
          >
            {t("landing.callToAction.callToAction")}
          </button>
        </section>

        {/* <!-- FAQ Section --> */}
        <section className="py-16 px-8 md:px-16 bg-dark-blue text-white text-center min-h-screen">
          <h2 className="text-3xl font-semibold mb-8">
            {t("landing.faq.title")}
          </h2>
          <div className="flex items-center justify-center h-full">
            <div className="space-y-6 text-left max-w-2xl mx-auto self">
              <div className="bg-gray-700 p-4 rounded-md">
                <h3 className="font-semibold text-lg">
                  {t("landing.faq.question1")}
                </h3>
                <p className="mt-2">{t("landing.faq.answer1")}</p>
              </div>
              <div className="bg-gray-700 p-4 rounded-md">
                <h3 className="font-semibold text-lg">
                  {t("landing.faq.question2")}
                </h3>
                <p className="mt-2">{t("landing.faq.answer2")}</p>
              </div>
              <div className="bg-gray-700 p-4 rounded-md">
                <h3 className="font-semibold text-lg">
                  {t("landing.faq.question3")}
                </h3>
                <p className="mt-2">{t("landing.faq.answer3")}</p>
              </div>
              <div className="bg-gray-700 p-4 rounded-md">
                <h3 className="font-semibold text-lg">
                  {t("landing.faq.question4")}
                </h3>
                <p className="mt-2">{t("landing.faq.answer4")}</p>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Subscribe Section --> */}
        <section
          className="flex flex-col items-center py-16 px-8 md:px-16 bg-dark-blue text-white text-center min-h-9/10"
          id="subscribe"
        >
          <h2 className="text-3xl font-semibold mb-8">
            {t("landing.subscribe.title")}
          </h2>

          <div className="md:w-1/2">
            <RegisterToNewsletterForm />
          </div>
        </section>
      </PageWrapper>
    </>
  );
};

export default Landing;
