/* eslint-disable @typescript-eslint/ban-ts-comment */
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Landing from "./pages/Landing";
import ProtectedForYou from "./pages/ForYou";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import ContactUs from "./pages/ContactUs";
import ResetPassword from "./pages/ResetPassword";
import { ToastProvider } from "./contexts/ToastContext";

const App: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 1000 * 60 * 60 * 1, // 1 hour
      },
    },
  });

  const isLoginEnabled = true;

  return (
    <ToastProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/discover" element={<Landing />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/contact-us" element={<ContactUs />} />
            {isLoginEnabled && <Route path="/login" element={<Login />} />}
            {isLoginEnabled && <Route path="/signup" element={<Signup />} />}
            {isLoginEnabled && (
              <Route path="/reset-password" element={<ResetPassword />} />
            )}
            <Route path="/*" element={<ProtectedForYou />} />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </ToastProvider>
  );
};

export default App;
