import { useTranslation } from "react-i18next";
import PageWrapper from "../components/PageWrapper";

const ContactUs = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <div className="container mx-auto px-4 py-16  text-white">
        <h1 className="text-4xl font-bold mb-8">{t("contactUs.title")}</h1>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            {t("contactUs.getInTouch.title")}
          </h2>
          <p>{t("contactUs.getInTouch.content")}</p>
        </section>

        <form className="bg-gray-200 p-8 rounded-lg max-w-lg mx-auto">
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-gray-700 font-semibold mb-2"
            >
              {t("contactUs.form.nameLabel")}
            </label>
            <input
              type="text"
              id="name"
              className="w-full p-3 border rounded-md"
              placeholder={t("contactUs.form.namePlaceholder")}
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-gray-700 font-semibold mb-2"
            >
              {t("contactUs.form.emailLabel")}
            </label>
            <input
              type="email"
              id="email"
              className="w-full p-3 border rounded-md"
              placeholder={t("contactUs.form.emailPlaceholder")}
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="message"
              className="block text-gray-700 font-semibold mb-2"
            >
              {t("contactUs.form.messageLabel")}
            </label>
            <textarea
              id="message"
              className="w-full p-3 border rounded-md"
              rows={5}
              placeholder={t("contactUs.form.messagePlaceholder")}
              required
            />
          </div>

          <button
            type="submit"
            className="bg-blue-500 text-white font-semibold py-2 px-6 rounded-md hover:bg-blue-600"
          >
            {t("contactUs.form.submitButton")}
          </button>
        </form>
      </div>
    </PageWrapper>
  );
};

export default ContactUs;
