import { useTranslation } from "react-i18next";
import PageWrapper from "../components/PageWrapper";
import SignUpForm from "../components/SignUpFrom";

const Signup = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <div className="min-h-screen flex items-center justify-center bg-dark-blue">
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
          <h1 className="text-3xl font-bold mb-6">{t("signup.title")}</h1>

          <SignUpForm />

          <p className="text-center text-gray-600 mt-4">
            {t("signup.alreadyAccountText")}{" "}
            <a href="/login" className="text-light-blue hover:underline">
              {t("signup.loginLink")}
            </a>
          </p>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Signup;
