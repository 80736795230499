import { useState } from "react";
import { RiHeart3Fill, RiHeart3Line } from "react-icons/ri";
import { Post } from "../api/post";
import { useLike, useUnlike } from "../like";

interface LikeButtonProps {
  post: Post;
  demo?: boolean;
  color?: "white" | "black";
}

const LikeButton = ({
  post,
  demo = false,
  color = "black",
}: LikeButtonProps) => {
  const [liked, setLiked] = useState(post.liked);
  const [numberOfLike, setNumberOfLike] = useState(post.likes_count);

  const { mutate: like } = useLike();
  const { mutate: unlike } = useUnlike();

  const handleLike = () => {
    setLiked(true);
    setNumberOfLike(numberOfLike + 1);
    if (!demo) like(post.id);
  };
  const handleUnlike = () => {
    setLiked(false);
    setNumberOfLike(numberOfLike - 1);
    if (!demo) unlike(post.id);
  };

  const colorClass = color === "black" ? "text-dark-blue" : "text-white";

  return (
    <button
      className={
        "flex items-center px-2 hover:text-light-blue font-bold " + colorClass
      }
      onClick={() => (liked ? handleUnlike() : handleLike())}
    >
      {liked ? (
        <RiHeart3Fill className="h-6 w-6 text-red-600" />
      ) : (
        <RiHeart3Line className="h-6 w-6 " />
      )}
      {numberOfLike}
    </button>
  );
};

export default LikeButton;
