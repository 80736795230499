import {
  QueryKey,
  useQuery,
  QueryFunction,
  useQueryClient,
  UseQueryOptions,
  useMutation,
} from "@tanstack/react-query";
import axios from "axios";
import { useContext } from "react";

export type Role = "admin" | "moderator";
export interface User {
  id: string;
  email: string;
  username: string;
  profile_id?: string;
  role?: Role;
}

interface GetUserPayload {
  queryKey: QueryKey;
  setUser?: React.Dispatch<React.SetStateAction<User | null>>;
}

export const USER_QUERY_KEY = ["user"];

export const getUser: QueryFunction<User, QueryKey> = async ({
  setUser,
}: GetUserPayload) => {
  const response = await axios.get("/user");

  if (setUser && response.status === 200) {
    setUser({ ...response.data });
  }

  return response.data;
};

export const useUserShow = (params: UseQueryOptions | null = null) =>
  useQuery<User, Error>(USER_QUERY_KEY, getUser, {
    onSuccess: (data) => {},
    onError: () => console.log("error"),
    retry: 0,
    refetchOnMount: !!params?.refetchOnMount,
  });

export interface UpdateUserPayload {
  username: string;
}

export const updateUser = async (payload: UpdateUserPayload) => {
  const response = await axios.put("/user", payload);

  return response.data;
};

// export const useUpdateUser = async (payload: UpdateUserPayload) =>
//   useMutation({
//     mutationFn: async (payload: UpdateUserPayload) => {
//       const response = await axios.put("/user", payload);
//       return response.data;
//     },
//   });
