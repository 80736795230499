import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getPosts, Post } from "../api/post";
import ProtectedImage from "./design-system/ProtectedImage";
import LikeButton from "./LikeButton";
import ReportAbuseModal from "./ReportAbuseModal";
import SignalButton, { MoreButton } from "./SignalButton";
import ViewerModal from "./ViewModal";

const POSTS = [
  {
    id: "1",
    description: "post 1",
    photoUrl: "",
    videoUrl: "",
    username: "Username 1",
  },
  {
    id: "2",
    description: "post 2",
    photoUrl: "",
    videoUrl: "",
    username: "Username 2",
  },
  {
    id: "3",
    description: "post 3",
    photoUrl: "",
    videoUrl: "",
    username: "Username 3",
  },
  {
    id: "4",
    description: "post 4",
    photoUrl: "",
    videoUrl: "",
    username: "Username 4",
  },
];

interface PostItemProps {
  post: Post;
  demo?: boolean;
  lock?: boolean;
  textColor?: "white" | "black";
}

export const PostItem = ({
  post,
  demo = false,
  lock = false,
  textColor = "black",
}: PostItemProps) => {
  const [selectedPost, setSelectedPost] = useState<Post | null>(null);
  const [seeMore, setSeeMore] = useState(false);
  const navigate = useNavigate();

  const goToProfile = () => {
    if (!demo) navigate(`/${post.username}`);
  };

  return (
    <div className="flex-row border-b border-dark-blue pb-2 text-white">
      <div className="flex items-center pb-2">
        <ProtectedImage
          src={post.profile_pic}
          className="h-16 w-16 rounded-full border border-black"
        />
        {/* //Replace with a link */}
        <button
          className="font-bold algin-center text-xl pl-4 hover:text-light-blue"
          onClick={() => !demo && goToProfile()}
        >
          {post.username}
        </button>
      </div>
      <div
        className="flex justify-center  hover:bg-slate-600 cursor-pointer"
        onClick={() => setSelectedPost(post)}
      >
        <ProtectedImage
          lock={lock}
          src={post.file}
          className="bg-gray-400 hover:opacity-50 w-full"
        />
      </div>
      <div className="flex justify-end">
        <LikeButton post={post} demo color="white" />
        {!demo && <MoreButton postId={post.id} />}
      </div>
      <p className="text-left">{post.description}</p>
      {/* <div className="flex">
        <div className={seeMore ? "w-full" : "truncate"}>
          {post.description}
        </div>
        {!demo && !seeMore && (
          <button className="font-bold ml-2" onClick={() => setSeeMore(true)}>
            More
          </button>
        )}
      </div> */}
      {selectedPost && (
        <ViewerModal lock={lock} post={post} setPost={setSelectedPost} />
      )}
    </div>
  );
};

const PostsList = () => {
  const [posts, setPosts] = useState<Post[] | null>(null);

  useEffect(() => {
    getPosts().then((response) => {
      if (response.status === 200) setPosts(response.data);
    });
  }, []);

  return posts && posts.length ? (
    <div className="flex justify-center ">
      <div className="flex-row w-4/12 space-y-14">
        {posts.map((post) => {
          return <PostItem key={post.id} post={post} />;
        })}
      </div>
    </div>
  ) : (
    <div>We found nothing for the moment..</div>
  );
};

export default PostsList;
