import { Navigate } from 'react-router-dom'
import { useRevenuesShow } from '../api/revenues'
import { User, useUserShow } from '../api/user'
import Label from '../components/design-system/Label'
import Loader from '../components/design-system/Loader'
import { Chart } from 'react-google-charts';

interface Props {
  user: User
}

const Revenues = ({user}: Props) => {
  const {data: revenues, isLoading: loading, isError: error} = useRevenuesShow()

  const options = {
    title: 'Highest Temperature in last five years',
    backgroundColor: '#f7f7f7',
    hAxis: {
        title: 'Year',
    },
    vAxis: {
        title: 'Temperature °C',
    },
    colors: ['#007bff'],
    lineWidth: 2,
    pointSize: 6,
    animation: {
        startup: true,
        duration: 1000,
        easing: 'out',
    },
};

  return (
    <div className="px-44 py-20">
      <h1 className="text-3xl font-bold text-dark-blue mb-10">Revenues</h1>

      {error ?
        <p>Something went wrong... Please retry later</p>
        :
        <>

        <Chart
          chartType="LineChart"
          data={[['jours', 'revenue'], [5, 400], [6, 500], [7, 500], [8, 800], [9, 700], [10, 750]]}
          width="100%"
          height="400px"
          legendToggle
          options={options}
        />

        {revenues && Object.keys(revenues).map((objectKey: string, index: number) => {
          const integerPart = Math.trunc(revenues[objectKey])
          const decimalPart = Math.floor((revenues[objectKey] - integerPart) * 100)

          return (
            <div key={index}>
            <Label label={objectKey.replace(/_/g, ' ').toUpperCase() + ':'} align='end' className="w-60">
            <div className='flex justify-center'>
            {loading ?
              <Loader/>
              :
              <span className='flex items-end'>
                <p className='font-bold text-6xl'>{integerPart}</p>
                <p className='font-bold text-xl'>{'.' + decimalPart}</p>
                <p className='font-bold text-3xl ml-2'>$</p>
              </span>
            }
            </div>
            </Label>
            </div>
          )
        })}
      </>
      }
    </div>
  )
}

export default Revenues