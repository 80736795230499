import { RiCloseFill } from 'react-icons/ri'
import Button from './Button'
import Loader from './Loader'

interface Props {
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  title: string | JSX.Element
  children: JSX.Element
  primaryAction?: string | JSX.Element
  secondaryAction?: string
  onPrimaryClick?: () => void
  onSecondaryClick?: () => void
  hideFooter?: boolean 
  isLoading?: boolean
}

const Modal = ({showModal, setShowModal, title, primaryAction, secondaryAction, onPrimaryClick, onSecondaryClick, hideFooter = false, isLoading, children}: Props) => {
  return (
    <>
    {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto sm:w-11/12 lg:w-6/12">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    {title}
                  </h3>
                  
                  <button
                    className="p-1 ml-auto bg-transparent border-0 float-right outline-none focus:outline-none text-dark-blue"
                    onClick={() => setShowModal(false)}
                  >
                    <RiCloseFill  className='h-8 w-8 text-dark-blue  '/>

                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 w-full flex-auto">
                  {children}
                </div>
                {/*footer*/}
                {!hideFooter &&
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-light-blue background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => onSecondaryClick ? onSecondaryClick() : setShowModal(false)}
                  >
                    {secondaryAction ? secondaryAction : 'CLOSE'}
                  </button>
                  <Button
                    className=" text-white" // active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-500"
                    type="button"
                    onClick={() => onPrimaryClick ? onPrimaryClick() : setShowModal(false)}
                  >
                    {isLoading ?
                      <Loader/>
                    : primaryAction ? primaryAction : 'Save Changes'}
                  </Button>
                </div>
                }
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  )
}

export default Modal