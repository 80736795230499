import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

export interface Report {
  id: string
  reason: string
  description: string
  author: string
}
interface ReportPostPayload {
  postId: string
  reason: string
  description: string
}

interface useReportCreateProps {
  onSuccess: () => void
  onError: () => void
}

export const useReportCreate = ({onSuccess, onError}: useReportCreateProps) => {
  return useMutation({
      mutationFn: async (payload: ReportPostPayload) => {
      const response = await axios.post('/reports', {
        post_id: payload.postId,
        reason: payload.reason,
        description: payload.description,
      })

      return response
    },
    onSuccess: () => onSuccess(),
    onError: () => onError() 
  }
  )
}