import { useState } from "react";
import { LoginPayload, useAuthentication } from "../api/authentication";
import Input from "./design-system/Input";
import { useTranslation } from "react-i18next";
import { useToast } from "../contexts/ToastContext";

const LoginForm = () => {
  const [logInForm, setLogInForm] = useState<LoginPayload>({
    email: "",
    password: "",
  });

  const { login } = useAuthentication();
  const { addToast } = useToast();

  const handleLogIn = () => {
    // TODO use react query proper toast with translation
    login(logInForm);
    addToast("You are successfully logged in", "success");
  };
  const { t } = useTranslation();

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        handleLogIn();
      }}
    >
      <Input
        label={t("login.form.emailLabel")}
        placeholder={t("login.form.emailPlaceholder")}
        required
        onChange={(event: React.FormEvent<HTMLInputElement>) => {
          setLogInForm({ ...logInForm, email: event.currentTarget.value });
        }}
      />
      <Input
        label={t("login.form.passwordLabel")}
        placeholder={t("login.form.passwordPlaceholder")}
        type="password"
        required
        onChange={(event: React.FormEvent<HTMLInputElement>) => {
          setLogInForm({ ...logInForm, password: event.currentTarget.value });
        }}
      />

      <button
        type="submit"
        className="bg-light-blue text-white font-semibold py-2 px-6 rounded-md hover:opacity-70 w-full"
      >
        {t("login.form.submitButton")}
      </button>
    </form>
  );
};

export default LoginForm;
