import React, { useCallback } from "react";
import LanguagePicker from "../components/LanguagePicker";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Image from "../components/design-system/Image";
import FORYOU_LOGO from "../assets/4you-logo.png";

const PublicHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateToLogin = useCallback(() => navigate("/login"), [navigate]);
  const navigateToSignup = useCallback(() => navigate("/signup"), [navigate]);
  const navigateToDiscover = useCallback(
    () => navigate("/discover"),
    [navigate]
  );
  const isLoginEnabled = process.env.NODE_ENV === "development";

  return (
    <div className="fixed  bg-dark-blue text-white h-20 w-full z-50">
      <div className="flex justify-between">
        <Image
          src={FORYOU_LOGO}
          alt="4 YOU logo"
          className="h-16"
          onClick={navigateToDiscover}
        />
        <div className="p-2 pr-8 flex space-x-2 items-center">
          <LanguagePicker />

          {isLoginEnabled && (
            <>
              <button
                className="bg-transparent border-2 border-white hover:bg-white hover:text-gray-900 font-semibold py-1 px-2 md:py-3 md:px-6 rounded-md"
                onClick={navigateToLogin}
              >
                {t("common.actions.logIn")}
              </button>
              <button
                onClick={navigateToSignup}
                className="bg-light-blue hover:bg-blue-600 text-white font-semibold py-2 px-2 md:py-3 md:px-6 rounded-md"
              >
                {t("common.actions.signUp")}
              </button>
            </>
          )}
          {!isLoginEnabled && (
            <button
              onClick={() => {
                window.location.href = "#subscribe";
              }}
              className="bg-light-blue hover:bg-blue-600 text-white font-semibold py-3 px-6 rounded-md"
            >
              {t("common.actions.subscribe")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PublicHeader;
