import Footer from "./Footer";
import PublicHeader from "./PublicHeader";

const PageWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="bg-dark-blue">
      <PublicHeader />
      <div className="pt-10 min-h-screen">{children}</div>
      <Footer />
    </div>
  );
};

export default PageWrapper;
