import { RiLoader5Fill } from 'react-icons/ri'


const Loader = () => {
  return (
    <div className="flex justify-center items-center">
      <RiLoader5Fill className="animate-spin h-8 w-8"/>
    </div>
  )
}

export default Loader 