import { useTranslation } from "react-i18next";
import PageWrapper from "../components/PageWrapper";
import LoginForm from "../components/LogInForm";

const Login = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <div className="min-h-screen flex items-center justify-center bg-dark-blue">
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
          <h1 className="text-3xl font-bold mb-6">{t("login.title")}</h1>

          <LoginForm />

          <p className="text-center text-gray-600 mt-4">
            {t("login.noAccountText")}{" "}
            <a href="/signup" className="text-light-blue hover:underline">
              {t("login.signupLink")}
            </a>
          </p>
          <p className="text-center text-gray-600 mt-2">
            <a
              href="/reset-password"
              className="text-light-blue hover:underline"
            >
              {t("login.forgotPasswordLink")}
            </a>
          </p>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Login;
