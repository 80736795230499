import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Role, User, useUserShow } from '../api/user'
import Loader from './design-system/Loader';


interface PrivateRouteProps {
  authorizedRole?: Role[]
  children: (user: User) => JSX.Element;
}

export const ProtectedComponent = ({ authorizedRole, children }: PrivateRouteProps) => {
  const { data: user, isLoading, isError } = useUserShow()
  const navigate = useNavigate()
  
  if (isLoading) return <Loader />
  if (isError || !user) {
    navigate('/discover')
    // window.location.href = `${window.location.protocol}//${window.location.hostname.split('.')[1]}:3000/`
    return null
  }

  if (!authorizedRole) 
    return children(user)
  
  if (user.role && authorizedRole.includes(user.role))
    return children(user)
    
  navigate('/discover')

  // window.location.href = `${window.location.protocol}//${window.location.hostname.split('.')[1]}:3000/`
  return null
};
