import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const LANDING_QUERY_KEY = ["landing"];

export const useLanding = () => {
  return useQuery(LANDING_QUERY_KEY, async () => {
    const response = await axios.get("/landings");

    return response.data;
  });
};
