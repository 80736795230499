import { useEffect, useState } from 'react'
import { useReportCreate } from '../api/report'
import Modal from './design-system/Modal'
import TextArea from './design-system/TextArea'

interface ReportAbuseModalProps {
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  postId: string
}

const REPORT_ABUSE_FORM = { postId: '', reason: '', description: '' }

const ReportAbuseModal = ({showModal, setShowModal, postId}: ReportAbuseModalProps) => {
  const [reportForm, setReportForm] = useState(REPORT_ABUSE_FORM)
  const [reasonError, setReasonError] = useState(false)

  const onSuccess = () => {
    setShowModal(false)
  }

  const onError = () => {
    // display a toast
    setShowModal(false)
  }

  const { mutate, isLoading } = useReportCreate({onSuccess, onError})

  const handleCreateReport = () => {
    if (reportForm.reason)
      mutate(reportForm)
    else
      setReasonError(true)
  }

  useEffect(() => {
    setReportForm({ ...reportForm, postId: postId})
  }, [postId])

  useEffect(() => {
    if (reportForm.reason)
      setReasonError(false)
  }, [reportForm.reason])

  return (
    <Modal 
      title={'Report an abuse'}
      setShowModal={setShowModal}
      showModal={showModal}
      primaryAction={'Report'}
      onPrimaryClick={() => handleCreateReport()}
      isLoading={isLoading}
    >
      <>

        <select className="self-center  mt-4 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
          focus:outline-none focus:border-light-blue focus:ring-1 focus:ring-light-blue
          disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
          invalid:border-pink-500 invalid:text-pink-600
          focus:invalid:border-pink-500 focus:invalid:ring-pink-500 text-dark-blue"
          onChange={(e) => setReportForm({ ...reportForm, reason: e.target.value})}
        >
          <option value={''} selected>Choose a reason</option>
          <option value={'Inapropriate content'}>Inapropriate content</option>
          <option value={'The account is stealing someone identity'}>The account is stealing someone identity</option>
        </select>
        {reasonError && <div className="text-red-600 text-sm">Please select the reason of the report</div>}
        <TextArea 
          onChange={(e) => setReportForm({ ...reportForm, description: e.currentTarget.value})}
          value={reportForm.description}
          placeholder="You can add a description with more details"
        />


      </>
    </Modal>
  )
}

export default ReportAbuseModal