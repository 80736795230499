import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { User, USER_QUERY_KEY } from "./user";
import { useToast } from "../contexts/ToastContext";
export interface LoginPayload {
  email: string;
  password: string;
}
// export const login = async (payload: LoginPayload) => {
//   const response =  await axios.post('/users/sign_in', {
//     user: payload,
//   })

//   if (response.status === 200)
//     set

//   return response
// }

export interface SignUpPayload {
  email: string;
  password: string;
}

// export const signup = async (payload: SignUpPayload) => {
//   const response = await axios.post('/users', { user: payload })

//   return response
// }

// export const useLogout =  () => {
//   const queryClient = useQueryClient()
//   const navigate = useNavigate()
//   const { setUser } = useUser()

//   const logout = async () => {
//     const response = await axios.delete('/users/sign_out')

//     if (response.status === 200) {
//       navigate('/')
//       queryClient.invalidateQueries(USER_QUERY_KEY)
//       localStorage.removeItem('authHeaders');
//       setUser(null)
//     }

//     return response
//   }
//   return { logout }
// }

export const useAuthentication = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { addToast } = useToast();

  const login = async (payload: LoginPayload) => {
    localStorage.removeItem("auth_token");

    const response = await axios.post("/users/sign_in", {
      user: payload,
    });

    if (response.status === 200) {
      queryClient.setQueryData<User>(USER_QUERY_KEY, {
        ...response.data,
      });
      // axios.get('/user')
      // window.location.href = `${window.location.protocol}//app.${window.location.hostname}:3000/`
      navigate("/");
    }

    return response;
  };

  const signup = async (payload: SignUpPayload) => {
    const response = await axios.post("/users", { user: payload });

    return response;
  };

  const logout = async () => {
    const response = await axios.delete("/users/sign_out");

    if (response.status === 200) {
      queryClient.invalidateQueries(USER_QUERY_KEY);
      navigate("/discover");
      localStorage.removeItem("authHeaders");
    }
    return response;
  };

  const resetPassword = async (email: string) => {
    const response = await axios.post("/users/password", { user: { email } });

    addToast("Check your email to get your new password", "info");

    return response;
  };

  return {
    login,
    signup,
    logout,
    resetPassword,
  };
};
