import { useMutation } from '@tanstack/react-query'
import axios from 'axios'


export const useLike = () => {
  return useMutation(async (postId: string) => {
    const response = await axios.post('/like', { post_id: postId })
    return response
  })
}

export const useUnlike = () => {
  return useMutation(async (postId: string) => {
    const response = await axios.post('/like', {post_id: postId})
    return response
  })
}