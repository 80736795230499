import { useTranslation } from "react-i18next";
import PageWrapper from "../components/PageWrapper";
import { useAuthentication } from "../api/authentication";
import { useState } from "react";

const ResetPassword = () => {
  const { t } = useTranslation();

  const { resetPassword } = useAuthentication();

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setMessage("");
    setError("");

    try {
      await resetPassword(email);
      setMessage("Password reset email sent. Please check your inbox.");
    } catch (err: any) {
      setError("Failed to send password reset email. Please try again.");
    }
  };

  return (
    <PageWrapper>
      <div className="min-h-screen flex items-center justify-center bg-dark-blue">
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
          <h1 className="text-3xl font-bold mb-6">
            {t("resetPassword.title")}
          </h1>
          {message && <div className="text-green-600 mb-4">{message}</div>}
          {error && <div className="text-red-600 mb-4">{error}</div>}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-gray-700 font-semibold mb-2"
              >
                {t("resetPassword.form.emailLabel")}
              </label>
              <input
                type="email"
                id="email"
                className="w-full p-3 border rounded-md"
                placeholder={t("resetPassword.form.emailPlaceholder")}
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <button
              type="submit"
              className="bg-light-blue text-white font-semibold py-2 px-6 rounded-md hover:opacity-70 w-full"
            >
              {t("resetPassword.form.submitButton")}
            </button>
          </form>

          <p className="text-center text-gray-600 mt-4">
            <a href="/login" className="text-light-blue hover:underline">
              {t("resetPassword.backToLogin")}
            </a>
          </p>
        </div>
      </div>
    </PageWrapper>
  );
};

export default ResetPassword;
