interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  mode?: 'default' | 'outlined'
  children?: string | JSX.Element
  onClick?: () => void
  type?: 'button' | 'submit' | 'reset'
  className?: string
  size?: 'small' | 'medium'
  disabled?: boolean
  // color: string
}

const mainColor = 'light-blue'
const bgColor = `bg-${mainColor}`
const outlinedCSS = 'bg-transparent text-light-blue border-4 border-light-blue font-bold py-2 px-8 rounded-full hover:shadow'

const Button = ({ mode = 'default', type = 'button', onClick, className = '', size = 'medium', disabled = false, children, ...props }: IProps) => {
  // if (mode === 'default') return <button>{children}</button>
  // return <button>{children}</button>
  const inputSize = size === 'medium' ? 'h-12 px-12 ' : 'h-8 px-8 '
  const mainBgColor = disabled ? 'bg-slate-500 ' : 'bg-light-blue '
  switch (mode) {
    case 'outlined':
      return (
        <button
          onClick={onClick}
          type={type}
          className={'bg-transparent text-light-blue border-4 disabled:text-slate-500 disabled:border-slate-500 border-light-blue font-bold  rounded-full hover:shadow  ' + inputSize + className}
          disabled={disabled}
          {...props}
        >
          {children}
        </button>
      )
    case 'default':
      return (
        <button
          onClick={onClick}
          type={type}
          className={'font-bold rounded-full text-white ' + mainBgColor + inputSize  + className}
          disabled={disabled}
          {...props}
        >
          {children}
        </button>
      )
    default:
      return <div>There is something wrong here...</div>
  }
}

export default Button
