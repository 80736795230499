import { useState } from 'react'
import { useAuthentication } from '../api/authentication'
import { User } from '../api/user'
import Drawer from './design-system/Drawer'
import { RiUserAddFill, RiUserFill, RiBarChartFill, RiLoginBoxFill, RiSettings5Fill, RiContactsBookFill, RiFlag2Fill, RiFileChart2Fill, RiUserShared2Fill, RiGroupFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom'


interface SlidingPanelProps {
  user: User
}

const SlidingPanel = ({user}: SlidingPanelProps) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const { logout } = useAuthentication()

  const handleLogout = () => {
    logout()
  }

  const navigate = useNavigate()

  return (
    <Drawer
      isOpen={showSidebar}
      setIsOpen={setShowSidebar}>
      <>
        {
          user.role ? (
            <>
              <p>Admin:</p>
              <h3 className="mt-2 text-xl font-semibold text-white hover:text-light-blue cursor-pointer flex items-center">
                <RiGroupFill className='h-6 w-6 mr-2' />
                <a onClick={() => {
                  navigate('/admin/users')
                  setShowSidebar(false)
                }}>Users</a>
              </h3>

              <h3 className="mt-2 text-xl font-semibold text-white hover:text-light-blue cursor-pointer flex items-center">
                <RiFlag2Fill className='h-6 w-6 mr-2' />
                <a onClick={() => {
                  navigate('/admin/reports')
                  setShowSidebar(false)
                }}>Reports</a>
              </h3>
            
              <h3 className="mt-2 text-xl font-semibold text-white hover:text-light-blue cursor-pointer flex items-center">
                <RiBarChartFill className='h-6 w-6 mr-2' />
                <a onClick={() => {
                  navigate('admin/numbers')
                  setShowSidebar(false)
                }}>Numbers</a>
              </h3>
            
            </>
          ) : (
            <>
              <h3 className="mt-4 text-xl font-semibold text-white hover:text-light-blue cursor-pointer flex items-center">
                <RiSettings5Fill className='h-6 w-6 mr-2' />
                <a onClick={() => {
                  navigate('/settings')
                  setShowSidebar(false)}}>Settings</a>
                </h3>
                
                <h3 className="mt-2 text-xl font-semibold text-white hover:text-light-blue cursor-pointer flex items-center">
                {/* <RiUserFollowFill  className='h-6 w-6 mr-2' /> */}
                <RiContactsBookFill className='h-6 w-6 mr-2' />
                <a onClick={() => {
                  navigate('subscriptions')
                  setShowSidebar(false)}}>Subscriptions</a>
                </h3>

                { !user?.profile_id ? (
                  <h3 className="mt-2 text-xl font-semibold text-white hover:text-light-blue cursor-pointer flex items-center">
                    <RiUserAddFill  className='h-6 w-6 mr-2' />
                    <a onClick={() => {
                      navigate('/settings')
                      setShowSidebar(false)
                    }}>BECOME A CREATOR</a>
                  </h3>
                ) : (
                  <>
                  <p className='mt-20'>Creator:</p>
                  <h3 className="mt-2 text-xl font-semibold text-white hover:text-light-blue cursor-pointer flex items-center">
                    <RiUserFill  className='h-6 w-6 mr-2' />
                    <a className="cursor-pointer" onClick={() => {
                        user && navigate('/' + user?.username)
                      setShowSidebar(false)}}>Profile</a>
                  </h3>
                  
                  <h3 className="mt-2 text-xl font-semibold text-white hover:text-light-blue cursor-pointer flex items-center">
                    <RiBarChartFill className='h-6 w-6 mr-2' />
                    <a onClick={() => {
                      navigate('/revenues')
                      setShowSidebar(false)
                    }}>Revenues</a>
                  </h3>
                  </>
                )}
                
            </>
          )
        }

        <h3 className="mt-60 text-xl font-semibold text-white hover:text-light-blue cursor-pointer flex items-center">
        <RiLoginBoxFill className='h-6 w-6 mr-2'/>
          <a onClick={() => {
            handleLogout()
            setShowSidebar(false)}}>Logout</a>
        </h3>
      </>
    </Drawer>
  )
}


export default SlidingPanel