interface Props {
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  value: string
  placeholder?: string
  rows?: number
}

const TextArea = ({onChange, value, placeholder, rows = 4}: Props) => {
  return (
    <textarea rows={rows} className=" self-center  mt-4 mb-4 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
    focus:outline-none focus:border-light-blue focus:ring-1 focus:ring-light-blue
    disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
    invalid:border-pink-500 invalid:text-pink-600
    focus:invalid:border-pink-500 focus:invalid:ring-pink-500
   " 
      placeholder={placeholder}
      value={value}
      onChange={onChange}></textarea>
  )
}


export default TextArea