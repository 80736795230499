import { useState } from "react";
import { SignUpPayload, useAuthentication } from "../api/authentication";
import Input from "./design-system/Input";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useToast } from "../contexts/ToastContext";

interface IProps {}

const SignUpForm = ({}: IProps) => {
  const [signUpForm, setSignUpForm] = useState<SignUpPayload>({
    email: "",
    password: "",
  });
  const { signup } = useAuthentication();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addToast } = useToast();
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        // TODO filter email and passorbefore sending
        signup(signUpForm);
        addToast("You are successfully signed up", "success");
        navigate("/login");
      }}
    >
      <h2 className="text-2xl font-medium">Sign up to supports now !</h2>
      <Input
        label={t("signup.form.emailLabel")}
        placeholder={t("signup.form.emailPlaceholder")}
        required
        onChange={(event: React.FormEvent<HTMLInputElement>) => {
          setSignUpForm({ ...signUpForm, email: event.currentTarget.value });
        }}
      />
      <Input
        label={t("signup.form.passwordLabel")}
        placeholder={t("signup.form.passwordPlaceholder")}
        type="password"
        required
        onChange={(event: React.FormEvent<HTMLInputElement>) => {
          setSignUpForm({ ...signUpForm, password: event.currentTarget.value });
        }}
      />
      <button
        type="submit"
        className="bg-light-blue text-white font-semibold py-2 px-6 rounded-md hover:opacity-70 w-full"
      >
        {t("signup.form.submitButton")}
      </button>
    </form>
  );
};

export default SignUpForm;
